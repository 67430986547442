<template>
    <div>
        <div class="mb-3" >
            <strong style="color: #C25925;">
                貼心提醒：若之前有參加過之店家，請先點選忘記密碼，再以舊帳號與新密碼登入
            </strong>
        </div>
    </div>
    <div style="margin-bottom: 10rem;">
        <div class="mt-3 mb-3">
            <strong>
                <span class="recruit_btn">
                    <a href="/「2022臺中購物節」合作店家招募簡章.pdf" target="_blank" rel="noreferrer noopener">
                        <span><i class="bi bi-arrow-right-circle-fill recruit_icon"></i></span>
                        <span style="vertical-align: middle;">招募辦法</span>
                    </a>
                </span>
            </strong>
        </div>
    </div>
</template>