<template>
    <div class="register-page house-image" style="min-height: 100vh;">
        <div class="card" style="background-color: transparent; border: 0;">
            <div class="card-body py-0">
                <div class="card-title mb-3 title-bg-image">
                    <div class="logo-image">
                    </div>
                </div>
                <Form class="w-100" @submit="register()" :validation-schema="schema">
                    <div class="card" style="border: 0; background-color: transparent;">
                        <div class="card-header h3 text-primary" style="background-color: transparent; border: 0;">
                            臺中購物節店家平台合作店家註冊
                        </div>
                        <div class="card-body form-register">
                            <div class="form-floating mb-3">
                                <Field name="name" v-slot="{ meta, field }" v-model="register_form.name">
                                    <input
                                        id="name"
                                        v-bind="field"
                                        class="form-control"
                                        :class="{
                                            'is-valid': meta.valid && meta.touched,
                                            'is-invalid': !meta.valid && meta.touched,
                                        }"
                                        type="name"
                                        placeholder="姓名"
                                    />
                                </Field>
                                <label for="name">姓名</label>
                                <div id="name_help" class="form-text text-start">請輸入姓名</div>
                            </div>

                            <div class="input-group">
                                <div class="form-floating">
                                    <Field name="password" v-slot="{ meta, field }" v-model="register_form.password">
                                        <input
                                            id="password"
                                            v-bind="field"
                                            class="form-control"
                                            :class="{
                                                'is-valid': meta.valid && meta.touched,
                                                'is-invalid': !meta.valid && meta.touched,
                                            }"
                                            :type="pwd_type"
                                            placeholder="密碼"
                                            autocomplete="on"
                                        />
                                    </Field>
                                    <label for="password">密碼</label>
                                </div>
                                <button type="button" class="input-group-text btn-primary btn" v-on:click="show_pwd()">
                                    <i class="bi bi-eye-fill"></i>
                                </button>
                            </div>
                            <div id="password_help" class="form-text text-start mb-3">8-20碼，包含大寫、小寫英文及數字</div>

                            <div class="input-group">
                                <div class="form-floating">
                                    <Field name="password_confirmation" v-slot="{ meta, field }" v-model="register_form.password_confirmation">
                                        <input
                                            id="password_confirmation"
                                            v-bind="field"
                                            class="form-control"
                                            :class="{
                                                'is-valid': meta.valid && meta.touched,
                                                'is-invalid': !meta.valid && meta.touched,
                                            }"
                                            :type="pwd_confirm_type"
                                            placeholder="確認密碼"
                                            autocomplete="on"
                                        />
                                    </Field>
                                    <label for="password_confirmation">確認密碼</label>
                                </div>
                                <button type="button" class="input-group-text btn-primary btn" v-on:click="show_pwd_confirm()">
                                    <i class="bi bi-eye-fill"></i>
                                </button>
                            </div>
                            <div id="password_confirmation_help" class="form-text text-start mb-3">請再次輸入密碼</div>

                            <div class="form-floating mb-3">
                                <Field name="email" v-slot="{ meta, field }" v-model="register_form.email">
                                    <input
                                        id="email"
                                        v-bind="field"
                                        class="form-control"
                                        :class="{
                                            'is-valid': meta.valid && meta.touched,
                                            'is-invalid': !meta.valid && meta.touched,
                                        }"
                                        type="account"
                                        placeholder="帳號(Email)"
                                    />
                                </Field>
                                <label for="email">帳號(Email)</label>
                                <div id="email_help" class="form-text text-start">請輸入帳號(Email)</div>
                            </div>

                            <div class="row mb-3" style="padding: 0px 12px 0px 12px;">
                                <div class="px-0 col-6">
                                    <div class="input-group">
                                        <div class="form-floating">
                                            <Field name="captcha" v-slot="{ meta, field }" v-model="register_form.captcha">
                                                <input
                                                    id="floatingCaptcha"
                                                    v-bind="field"
                                                    class="form-control"
                                                    :class="{
                                                        'is-valid': meta.valid && meta.touched,
                                                        'is-invalid': !meta.valid && meta.touched,
                                                    }"
                                                    type="captcha"
                                                    placeholder="帳號"
                                                />
                                            </Field>
                                            <label for="floatingCaptcha">驗證碼</label>
                                        </div>
                                        <button type="button" class="input-group-text btn-primary btn" v-on:click="get_captcha()">
                                            <i class="bi bi-arrow-clockwise"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="px-0 col-6">
                                    <a @click="get_captcha" style="cursor: pointer;">
                                        <img :src="captcha_image" class="captcha-img">
                                    </a>
                                </div>
                            </div>

                            <div class="checkbox col-12 mb-3">
                                <label>
                                    <Field 
                                        type="checkbox"
                                        name="agree" 
                                        v-model="register_form.agree"
                                        v-slot="{ meta, field }"
                                        :value="true"
                                        :unchecked-value="false"
                                    >
                                        <input
                                            v-bind="field"
                                            :class="{
                                                'is-valid': meta.valid && meta.touched,
                                                'is-invalid': !meta.valid && meta.touched,
                                            }"
                                            class="form-check-input"
                                            type="checkbox"
                                            :value="true"
                                            :unchecked-value="false"
                                        />
                                    </Field>
                                </label>
                                <span>
                                    我同意<span style="cursor: pointer; color: #0d6efd;" data-bs-toggle="modal" data-bs-target="#staticBackdrop">隱私權與資訊安全政策</span>
                                </span>
                            </div>
                        
                            <button class="w-100 btn btn-lg btn-primary mb-3" type="submit">送出</button>

                            <div class="alert alert-success mb-3" role="alert" v-if="success_flag" style="white-space: pre-wrap;">
                                {{ success_message }}
                            </div>
                            <div class="alert alert-danger mb-3" role="alert" v-if="error_flag" style="white-space: pre-wrap;">
                                {{ error_message }}
                            </div>

                            <div class="mt-3 mb-3">
                                <span>已經是合作店家會員了嗎？ </span>
                            </div>
                            <div class="mb-3">
                                <strong>
                                    <span><router-link style="text-decoration: none;" to="/Login">立即登入</router-link></span>
                                </strong>
                            </div>
                        </div>
                        <BeforeLoginFooter/>
                    </div>
                </Form>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content" style="color: #000000;">
                <div class="modal-header text-center bg-dark text-white">
                    <h5 class="modal-title w-100" id="staticBackdropLabel">
                        隱私權與資訊安全政策
                    </h5>
                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="col-12 row text-start mx-auto">
                        <div class="col-12 mb-3">
                            <p>
                                非常歡迎您光臨「臺中購物節店家平台」（以下簡稱本網站），為了讓您能夠安心的使用本網站的各項服務與資訊，特此向您說明本網站的隱私權保護政策，以保障您的權益，請您詳閱下列內容：
                            </p>
                            <p>
                                一、隱私權保護政策的適用範圍
                                <ul>
                                    隱私權保護政策內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。
                                </ul>
                            </p>
                            <p>
                                二、個人資料的蒐集、處理及利用方式
                                <ul>
                                    <li>當您造訪本網站或使用本網站所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本網站不會將個人資料用於其他用途。</li>
                                    <li>本網站在您使用服務信箱、問卷調查等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。</li>
                                    <li>於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的 IP 位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。</li>
                                    <li>為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。</li>
                                    <li>您可以隨時向我們提出請求，以更正或刪除本網站所蒐集您錯誤或不完整的個人資料。</li>
                                </ul>
                            </p>
                            <p>
                                三、資料之保護
                                <ul>
                                    <li>本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。</li>
                                    <li>如因業務需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。</li>
                                </ul>
                            </p>
                            <p>
                                四、網站對外的相關連結
                                <ul>
                                    本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。
                                </ul>
                            </p>
                            <p>
                                五、與第三人共用個人資料之政策
                                <ul>
                                    本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。前項但書之情形包括不限於：
                                    <li>經由您書面同意。</li>
                                    <li>法律明文規定。</li>
                                    <li>為免除您生命、身體、自由或財產上之危險。</li>
                                    <li>與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集者依其揭露方式無從識別特定之當事人。</li>
                                    <li>當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。</li>
                                    <li>有利於您的權益。</li>
                                    <li>本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。</li>
                                </ul>
                            </p>
                            <p>
                                六、Cookie 之使用
                                <ul>
                                    為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的 Cookie，若您不願接受 Cookie 的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕 Cookie 的寫入，但可能會導致網站某些功能無法正常執行。
                                </ul>
                            </p>
                            <p>
                                七、隱私權保護政策之修正
                                <ul>
                                    本網站隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">關閉</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { register } from '../../api/auth';
import { captcha } from '../../api/captcha';
import { Field, Form } from 'vee-validate';
import { register_rules } from '../../rules/user/register';
import BeforeLoginFooter from "../../components/BeforeLoginFooter.vue";

export default {
    components: {
        Field,
        Form,
        BeforeLoginFooter,
    },
    data() {
        return {
            register_form: {
                name: "",
                password: "",
                password_confirmation: "",
                email: "",
                agree: false,
                captcha: null,
                captcha_key: null,
            },
            success_message: "",
            success_flag: false,
            error_message: "",
            error_flag: false,
            captcha_image: "",
            pwd_type: "password",
            pwd_confirm_type: "password",
        }
    },
    methods: {
        register() {
            this.error_flag = false;
            this.error_message = "",
            this.success_flag = false;
            this.success_message = "",
            register(this.register_form).then(response => {
                this.error_flag = false;
                this.error_message = "",
                this.success_message = response.data.message;
                this.success_flag = true;
            }, error => {
                this.success_flag = false;
                this.success_message = "",
                this.error_message = error.response.data.message;
                this.error_flag = true;
                this.get_captcha();
            });
        },
        get_captcha() {
            captcha().then(response => {
                this.captcha_image = response.data.img;
                this.register_form.captcha_key = response.data.key;
            })
        },
        show_pwd() {
            if (this.pwd_type === 'password') {
                this.pwd_type = 'text';
            } else {
                this.pwd_type = 'password';
            }
        },
        show_pwd_confirm() {
            if (this.pwd_confirm_type === 'password') {
                this.pwd_confirm_type = 'text';
            } else {
                this.pwd_confirm_type = 'password';
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        this.get_captcha();
    },
    computed: {
        schema() {
            return register_rules;
        },
    }
};
</script>
